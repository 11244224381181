import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { RadioRow } from 'common-front/src/designSystem/components/radioRow';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ParentDelegationsQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { encodeBase64 } from 'common/src/util/base64';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useVolunteersRegistrationsAdminAddMutation } from '../../generated/graphqlHooks';

interface ICreateDelegationModalProps {
    parentDelegations: ParentDelegationsQuery['organization']['delegations']['nodes'];
}

export const CreateDelegationModal = (props: ICreateDelegationModalProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const {
        mutate: volunteersRegistrationsAdminAdd,
        isLoading: isVolunteersRegistrationsAdminAddLoading
    } = useVolunteersRegistrationsAdminAddMutation();
    const [isFromCommunity, setIsFromCommunity] = React.useState(false);
    const [parentDelegationId, setParentDelegationId] = React.useState([]);
    const [applyParentSettings, setApplyParentSettings] = React.useState(true);
    const isValid = isFromCommunity ? isNonEmptyArray(parentDelegationId) : true;
    const onSuccess = async () => {
        let options: string | undefined = undefined;

        if (isFromCommunity && isNonEmptyArray(parentDelegationId)) {
            const selectedParentDelegationId = parentDelegationId[0];
            const parentDelegation = props.parentDelegations.find(
                (d) => d.id === selectedParentDelegationId
            )!;
            await volunteersRegistrationsAdminAdd({
                eventId,
                usersInfosIds: parentDelegation.leadersIds,
                sendVolunteerRegistrationEmail: false
            });

            options = encodeBase64(
                JSON.stringify({
                    parentId: selectedParentDelegationId,
                    applyParentSettings
                })
            );
        }

        history.push(HeaventPaths.CREATE_DELEGATION(organizationId, eventId, options));
    };

    return (
        <Modal.Portal>
            <Modal.Header icon="user-group" subtitle={translate('ajoutez_une_d_l_54234')}>
                {translate('cr_ation_d_une_07120')}
            </Modal.Header>

            <Modal.Content>
                <Box font="gray800 textSm medium">{translate('que_souhaitez_v_87607')}</Box>

                <Spacer height="2" />

                <RadioRow
                    state={isFromCommunity ? 'unchecked' : 'checked'}
                    onClick={() => {
                        setIsFromCommunity(false);
                    }}
                >
                    {translate('cr_er_une_nouve_45377')}
                </RadioRow>

                <Spacer height="2" />

                <RadioRow
                    state={isFromCommunity ? 'checked' : 'unchecked'}
                    onClick={() => {
                        setIsFromCommunity(true);
                    }}
                >
                    {translate('cr_er_une_d_l_g_37657')}
                </RadioRow>

                {isFromCommunity && (
                    <>
                        <Spacer height="7" />

                        <RichSelect
                            isSearchVisible={true}
                            multiple={false}
                            values={parentDelegationId}
                            onChange={setParentDelegationId}
                            label={translate('_quelle_d_l_ga_55758')}
                        >
                            {props.parentDelegations.map((delegation) => (
                                <option key={delegation.id} value={delegation.id}>
                                    {delegation.name}
                                </option>
                            ))}
                        </RichSelect>

                        <Spacer height="2" />

                        <ToggleText value={applyParentSettings} onChange={setApplyParentSettings}>
                            {translate('je_souhaite_app_69393')}
                        </ToggleText>
                    </>
                )}
            </Modal.Content>

            <Modal.Buttons>
                <Button
                    disabled={!isValid}
                    isLoading={isVolunteersRegistrationsAdminAddLoading}
                    onClick={onSuccess}
                >
                    {translate('suivant_62774')}
                </Button>
            </Modal.Buttons>
        </Modal.Portal>
    );
};
