import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

interface IPrimroseAccreditationProps {
    background: string;
    name: React.ReactNode;
}

export const PrimroseAccreditation = (props: IPrimroseAccreditationProps) => (
    <Flex align="center" gap="2">
        <Box height={44} width={44}>
            <img
                src={`https://assets.recrewteer.com/badges/primrose/${props.background}`}
                height="100%"
                width="100%"
            />
        </Box>

        <Box
            css={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '500',
                textTransform: 'uppercase'
            }}
        >
            {props.name}
        </Box>
    </Flex>
);
