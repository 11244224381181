import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { PrimroseAccreditation } from '../primrose/primroseAccreditation';

interface IBadgeOpenVendeeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeOpenVendee = (props: IBadgeOpenVendeeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const auis = vr.accreditationsUsersInfos;
    const acronyms = auis.map(({ accreditation }) => accreditation.acronym);
    const validityAuis = sortBy(
        auis.filter(({ accreditation }) => accreditation.id === 3449),
        ({ accreditationSlot }) => accreditationSlot.date?.toMillis() ?? 0
    );
    const validity =
        validityAuis.length === 8
            ? 'Semaine'
            : validityAuis
                  .map(
                      ({ accreditationSlot }) =>
                          accreditationSlot.date
                              ?.toFormat('ccc', { locale: 'fr' })
                              .substring(0, 3) ?? ''
                  )
                  .join(' / ');

    return (
        <>
            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/openvendee/recto_v2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$work',
                    position: 'relative'
                }}
            >
                <Box
                    height={126}
                    width={135}
                    css={{
                        border: '4px solid #1f2547',
                        borderRadius: '16px',
                        left: '19px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '85px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    width={210}
                    textAlign="center"
                    css={{
                        color: '#4e358c',
                        fontSize: '20px',
                        fontWeight: '900',
                        left: '166px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '107px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={210}
                    textAlign="center"
                    css={{
                        color: '#4e358c',
                        fontSize: '20px',
                        fontWeight: '900',
                        left: '166px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '128px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    width={210}
                    textAlign="center"
                    css={{
                        fontSize: '16px',
                        fontWeight: '900',
                        left: '166px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '165px'
                    }}
                >
                    {ui.openVendeePopulation}
                </Box>

                {isNonEmptyString(ui.fields.cf38196) && (
                    <Box
                        width={210}
                        textAlign="center"
                        css={{
                            fontWeight: '500',
                            left: '166px',
                            position: 'absolute',
                            textTransform: 'uppercase',
                            top: '187px'
                        }}
                    >
                        {ui.fields.cf38196}
                    </Box>
                )}

                <Box
                    width={312}
                    textAlign="center"
                    css={{
                        fontSize: '22px',
                        fontWeight: '700',
                        left: '42px',
                        lineHeight: '22px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '257px'
                    }}
                >
                    {validity}
                </Box>

                <Flex
                    direction="column"
                    css={{
                        gap: '14px',
                        left: '23px',
                        position: 'absolute',
                        top: '349px'
                    }}
                >
                    {acronyms.includes('TRI') && (
                        <PrimroseAccreditation background="Match.png" name="Tribune" />
                    )}

                    {acronyms.includes('ORGA') && (
                        <PrimroseAccreditation
                            background="Orga.png"
                            name={
                                <>
                                    Zone Staff /<br />
                                    Organisation
                                </>
                            }
                        />
                    )}

                    {acronyms.includes('REST') && (
                        <PrimroseAccreditation background="Catering.png" name="Zone Restaurant" />
                    )}
                </Flex>

                <Flex
                    direction="column"
                    css={{
                        gap: '14px',
                        left: '221px',
                        position: 'absolute',
                        top: '349px'
                    }}
                >
                    {acronyms.includes('VIP') && (
                        <PrimroseAccreditation background="Partners.png" name="Espace VIP" />
                    )}

                    {acronyms.includes('JOUEURS') && (
                        <PrimroseAccreditation background="Players.png" name="Zone Joueurs" />
                    )}

                    {acronyms.includes('BENE') && (
                        <PrimroseAccreditation background="Public.png" name="Zone Bénévole" />
                    )}
                </Flex>
            </Flex>

            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/openvendee/verso.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
