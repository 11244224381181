import { ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    ExportFormat,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { UUID } from 'common/src/util/uuid';
import { compact } from 'lodash-es';
import * as React from 'react';
import { VolunteersExportColumnsSelector } from './volunteersExportColumnsSelector';
import { VolunteersExportOptions } from './volunteersExportOptions';

interface IVolunteersExportPanelProps {
    basePath: string;
    customFields: SegmentCustomFieldFragment[];
    eventId: Emptyable<EventId>;
    isLoading: boolean;
    organizationId: OrganizationId;

    onClose(): void;
    requestExport(format: ExportFormat, includeFiles: boolean, columns: string[]): Promise<UUID>;
}

export const VolunteersExportPanel = ({
    basePath,
    customFields,
    eventId,
    isLoading,
    onClose,
    organizationId,
    requestExport
}: IVolunteersExportPanelProps) => {
    const translate = useTranslate();
    const { columnsExport, possibleColumns } = useSegmentsContext();
    const panelBodyRef = React.useRef<HTMLDivElement | null>(null);
    const [filesState, setFilesState] = React.useState<ICheckboxState>('unchecked');

    const selectedFields = React.useMemo(
        () =>
            columnsExport.flatMap((slug) =>
                compact([possibleColumns.find((pc) => pc.slug === slug)])
            ),
        [possibleColumns, columnsExport]
    );

    return (
        <RightPanel size="md" onClose={onClose}>
            <RightPanelHeader>
                <Box color="gray900" fontSize="textXl" fontWeight="medium">
                    {translate('exporter_vos_me_10045')}
                </Box>
            </RightPanelHeader>

            <RightPanelBody ref={panelBodyRef}>
                <Spacer height="7" />

                <VolunteersExportColumnsSelector
                    columns={selectedFields}
                    customFields={customFields}
                    filesState={filesState}
                    filesStateOnClick={() =>
                        setFilesState(filesState === 'unchecked' ? 'checked' : 'unchecked')
                    }
                />

                <Spacer height="9" />

                <VolunteersExportOptions
                    basePath={basePath}
                    eventId={eventId}
                    filesState={filesState}
                    isLoading={isLoading}
                    onClose={onClose}
                    organizationId={organizationId}
                    requestExport={requestExport}
                />

                <Spacer height="6" />
            </RightPanelBody>
        </RightPanel>
    );
};
